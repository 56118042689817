// ==============================
// * [PARTIAL] MINI CART
// Extends app_storefront_base
// ? This file gathers the mini-cart styles.
// ==============================
//

@import "base/miniCart";
@import "./productCard";


.header {
    .minicart {
        cursor: default;

        .popover {
            padding-top: 10px;
            background-color: $white;
            border: none;
            border-radius: 0;
            box-shadow: $box-shadow;

            a {
                color: inherit;
            }
        }

        &-link {
            position: relative;
            line-height: 1.5;
        }

        &-quantity {
            position: absolute;
            top: -5px;
            left: 11px;
            width: 18px;
            height: 18px;
            padding: 0;
            border-radius: 50%;
            background-color: $black;
            font-size: rem(11px);
            line-height: 18px;
        }

        &-container {
            background-color: $white;
        }

        &-wrapper {
            padding: 20px 10px 0;
        }

        .card {
            border: 0;

            &-body {
                padding: 0;
            }
        }

        &-title {
            margin-left: 10px;
            font-size: $font-size-base;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
        }

        &-footer {
            border-top: 0 !important; //!imporant is use here to override Storefront style
            padding: 0 10px 20px;

            .sub-total {
                text-transform: uppercase;
                text-align: center;
            }

            .cart-link {
                color: $white;
            }
        }
    }
}
