//==============================
//* [PARTIAL] SPINNER
// Extends app_storefront_base
//? This file imports the spinner from SFRA stylesheets and custom spinner CSS.
//==============================

@import "base/components/spinner";


// -------------------------------------
// SPINNER
// -------------------------------------

.veil {
    .underlay {
        opacity: 1;
        background-color: $grey-transparent-2;
    }
}
