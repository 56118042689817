// ==============================
// * [PARTIAL] PRODUCT CARDS
// Extends app_storefront_base
// ? This file gathers the product cards styles.
// ==============================
//

@import "base/productCard";


.card {
    margin-bottom: 10px;
    background-color: transparent;
    border: none;

    .product-line-item {
        position: relative;

        &-details {
            &__image {
                height: auto;
                min-height: 160px;
                padding: $grid-gutter-width 0;
                background-color: $light-gray;
                border-radius: $border-radius-lg;

                .product-image {
                    max-height: none;
                    width: 125px;
                    aspect-ratio: 2/2;
                    object-fit: contain;
                }
            }

            &__description {
                align-self: end;
                margin: 0 0 20px 20px;

                .line-item-name {
                    margin-bottom: 10px;
                    color: inherit;
                    font-weight: $font-weight-bold;
                    text-transform: uppercase;
                    white-space: unset;

                    p {
                        margin: 0;
                    }
                }

                .item {
                    &-attributes {
                        margin-bottom: 10px;
                    }

                    &-price {
                        .pricing {
                            display: inline-block;
                            font-weight: $font-weight-normal;
                        }

                        &-promo {
                            display: inline-block;

                            .promotion {
                                display: inline-block;
                                margin: 4px 0 0 4px;
                                padding: 2px 6px;
                                background-color: $dark-gray;

                                .old-price {
                                    text-decoration: line-through;
                                }

                                .callout-message {
                                    display: inline-block;
                                    margin-left: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .remove-product {
            @include appearance-none;
            @include make-transition(opacity);

            position: absolute;
            top: 0;
            right: 0;
            margin: 0;
            opacity: 1;
            color: inherit;

            &:hover {
                opacity: 0.7;
            }

            > i {
                font-size: rem(25px);
            }
        }
    }
}
