// ==============================
// * [PARTIAL] COMMON
// Override app_storefront_base
// ? This file gathers all the custom common CSS.
// ==============================
//
//
// MISCELLANEOUS
// ERROR BLOCK
// ONE TRUST


// -------------------------------------
// MISCELLANEOUS
// -------------------------------------

.modal-background {
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    display: none;
    opacity: 0.3;
    width: 100%;
    height: 100%;
    background-color: $black;
    cursor: pointer;
}

input[placeholder] {
    text-overflow: ellipsis;
}

.hide-order-discount {
    display: none;
}

.hide-shipping-discount {
    display: none;
}

.error-messaging {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
}

.error-hero {
    background-image: url("../images/storelocator.jpg");
    margin-bottom: 0.625em;
}

.error-message {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
}

.error.continue-shopping {
    margin-bottom: 6.25em;
}

.error-unassigned-category {
    color: $red;
}

.skip {
    position: absolute;
    left: 0;
    top: -4.2em;
    overflow: hidden;
    padding: 1em 1.5em;
    background: $white;
    transition: all 0.2s ease-in-out;
}

a.skip:active,
a.skip:focus,
a.skip:hover {
    left: 0;
    top: 0;
    width: auto;
    height: auto;
    z-index: 10000000;
    background: $white;
    transition: all 0.2s ease-in-out;
}

.card-header-custom {
    font-size: 1.5rem;
    margin-bottom: 0;
}


// -------------------------------------
// ERROR BLOCK
// -------------------------------------

.error-block {
    $self: &;

    &__wrapper {
        position: relative;
        height: 700px;
        overflow: hidden;

        @include media-breakpoint-up(lg) {
            &.horizontal {
                #{$self} {
                    &__content {
                        @include element-centered-Y;

                        display: block;
                        width: auto;
                    }

                    &__visual {
                        position: absolute;
                        top: 0;
                        right: 0;
                        left: auto;
                        width: 50vw;
                        height: 100%;
                    }
                }
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        height: 350px;

        .subtitle {
            margin: $grid-gutter-width 0;
            font-weight: $font-weight-light;
            font-size: rem(30px);
        }
    }

    &__visual {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100vw;
        height: 350px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}


// -------------------------------------
// ONE TRUST
// -------------------------------------

body {
    position: relative;

    @include media-breakpoint-down(sm) {
        #footer {
            padding-bottom: calc(#{$input-btn-padding-y} * 8);
        }
    }
}

#ot-sdk-btn {
    &.ot-sdk-show-settings,
    &.optanon-show-settings {
        position: absolute;
        bottom: calc(#{$input-btn-padding-y} * 2);
        left: 0;
        border: 1px solid $white !important; // !important is used here to override One Trust style
        border-radius: $border-radius-sm;
        background: $black;
        color: $white !important; // !important is used here to override One Trust style

        @include media-breakpoint-down(sm) {
            bottom: calc(#{$input-btn-padding-y} * 9);
        }

        &:hover,
        &:focus {
            border-color: $white !important; // !important is used here to override One Trust style
            background: $black !important; // !important is used here to override One Trust style
            color: $white !important; // !important is used here to override One Trust style
        }
    }
}

