// ==============================
// * [PARTIAL] FONTS IMPORTS
//
// ? This file gathers all the fonts imports.
// ==============================
//
// MULISH
//

@import "../variables";

// ----------------------------------------------------
// MULISH
// ----------------------------------------------------

@font-face {
    font-family: "Mulish";
    font-weight: $font-weight-base;
    font-display: swap;
    src: url("#{$mulish-font-path}Mulish-Regular.woff2") format("woff2"), url("#{$mulish-font-path}Mulish-Regular.woff") format("woff");
}

@font-face {
    font-family: "Mulish";
    font-weight: $font-weight-bold;
    font-display: swap;
    src: url("#{$mulish-font-path}Mulish-Bold.woff2") format("woff2"), url("#{$mulish-font-path}Mulish-Bold.woff") format("woff");
}

@font-face {
    font-family: "Mulish";
    font-weight: $font-weight-bolder;
    font-display: swap;
    src: url("#{$mulish-font-path}Mulish-ExtraBold.woff2") format("woff2"), url("#{$mulish-font-path}Mulish-ExtraBold.woff") format("woff");
}

@font-face {
    font-family: "Mulish";
    font-weight: $font-weight-black;
    font-display: swap;
    src: url("#{$mulish-font-path}Mulish-Black.woff2") format("woff2"), url("#{$mulish-font-path}Mulish-Black.woff") format("woff");
}
