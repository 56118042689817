// ==============================
// * [PARTIAL] PRODUCT TILES
// Extends app_storefront_base
// ? This file gathers the product tiles styles.
// ==============================
//

@import "base/components/productTiles";

.region {
    padding: 0 5px;
}

.product-tile {
    .image-container {
        padding: 40px 0;
        background-color: $light-gray;
        border-radius: $border-radius-lg;

        @include media-breakpoint-up(lg) {
            padding: 70px 0;

            &:not(.single):hover .tile-image.primary {
                opacity: 0;
            }
        }

        .image-link {
            aspect-ratio: 2/2;

            &:hover {
                opacity: 1;
            }
        }

        .tile-image {
            width: 100%;
            aspect-ratio: 2/2;
            object-fit: contain;

            @include media-breakpoint-up(lg) {
                &.primary {
                    @include make-transition(opacity);

                    position: relative;
                    z-index: 1;
                    background-color: $light-gray;
                }

                &.secondary {
                    @include element-centered-Y;
                }
            }
        }

        .tile-wishlist {
            position: absolute;
            z-index: 1;
            top: 10px;
            right: 10px;
            text-decoration: none;
            font-size: rem(18px);

            @include media-breakpoint-up(lg) {
                top: 20px;
                right: 20px;
            }

            @include media-breakpoint-up(xl) {
                top: 35px;
                right: 35px;
            }
        }

        .product-labels {
            position: absolute;
            top: 12px;
            left: 12px;
            z-index: 1;

            @include media-breakpoint-up(lg) {
                top: 20px;
                left: 0;
                display: flex;
                justify-content: center;
                width: 100%;
            }

            @include media-breakpoint-up(xl) {
                top: 35px;
            }

            li {
                text-align: center;

                a {
                    text-decoration: none;
                }
            }

            &-plp {
                .product-labels__item {
                    @include media-breakpoint-down(md) {
                        display: none;

                        &:first-child() {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    .product-link,
    .brand-link {
        text-decoration: none;
    }

    .product-link {
        display: inline-block;
        margin: 2px 0;
    }

    .product-name {
        line-height: rem(18px);
        text-transform: uppercase;

        &__brand {
            display: block;
            font-weight: $font-weight-bold;
        }
    }

    .price {
        font-size: rem(16px);

        > * {
            font-weight: $font-weight-normal;
        }

        .sales {
            font-weight: 700;
            margin-right: 4px;
        }
    }

    .promotion {
        display: inline-block;
        padding: 2px 6px;
        background-color: $dark-gray;
        font-size: $font-size-sm;

        .old-price {
            text-decoration: line-through;
        }

        .callout-message {
            display: inline-block;
            margin-left: 10px;
            font-weight: 700;
        }
    }

    .range .promotion .callout-message {
        margin-left: 0;
    }

    .color-swatches {
        min-height: none;

        .swatch-circle {
            margin-right: 4px;
            height: 16px;
            width: 16px;
            border: none;
            border-radius: $border-radius-circle;
        }
    }
}
