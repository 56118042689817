// ==============================
// * [PARTIAL] FONTICONS STYLES
//
// ? This file gathers all the fonticons styles.
// ==============================
//
// CWF
// LAUNDRY CARE
//

@import "variables";

// ----------------------------------------------------
// CWF
// ----------------------------------------------------

@font-face {
    font-family: "#{$cwf-font-family}";
    src:
        url("#{$cwf-font-path}/#{$cwf-font-family}.ttf?h31uxc") format("truetype"),
        url("#{$cwf-font-path}/#{$cwf-font-family}.woff?h31uxc") format("woff"),
        url("#{$cwf-font-path}/#{$cwf-font-family}.svg?h31uxc##{$cwf-font-family}") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

i:not(.fa)[class^="cwf-"],
i:not(.fa)[class*=" cwf-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "#{$cwf-font-family}" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.cwf-eye {
    &::before {
        content: $cwf-eye;
    }
}

.cwf-close {
    &::before {
        content: $cwf-close;
    }
}

.cwf-filter {
    &::before {
        content: $cwf-filter;
    }
}

.cwf-arrow {
    &::before {
        content: $cwf-arrow;
    }
}

.cwf-google {
    &::before {
        content: $cwf-google;
    }
}

.cwf-logo {
    &::before {
        content: $cwf-logo;
    }
}

.cwf-visa {
    &::before {
        content: $cwf-visa;
    }
}

.cwf-paypal {
    &::before {
        content: $cwf-paypal;
    }
}

.cwf-mastercard {
    &::before {
        content: $cwf-mastercard;
    }
}

.cwf-star {
    &::before {
        content: $cwf-star;
    }
}

.cwf-mixcloud {
    &::before {
        content: $cwf-mixcloud;
    }
}

.cwf-buble-bar {
    &::before {
        content: $cwf-buble-bar;
    }
}

.cwf-buble-check {
    &::before {
        content: $cwf-buble-check;
    }
}

.cwf-buble-cross {
    &::before {
        content: $cwf-buble-cross;
    }
}

.cwf-buble-i {
    &::before {
        content: $cwf-buble-i;
    }
}

.cwf-cart {
    &::before {
        content: $cwf-cart;
    }
}

.cwf-copy {
    &::before {
        content: $cwf-copy;
    }
}

.cwf-display {
    &::before {
        content: $cwf-display;
    }
}

.cwf-dropdown {
    &::before {
        content: $cwf-dropdown;
    }
}

.cwf-drum {
    &::before {
        content: $cwf-drum;
    }
}

.cwf-email {
    &::before {
        content: $cwf-email;
    }
}

.cwf-embroidery {
    &::before {
        content: $cwf-embroidery;
    }
}

.cwf-facebook {
    &::before {
        content: $cwf-facebook;
    }
}

.cwf-instagram {
    &::before {
        content: $cwf-instagram;
    }
}

.cwf-ironing {
    &::before {
        content: $cwf-ironing;
    }
}

.cwf-javel {
    &::before {
        content: $cwf-javel;
    }
}

.cwf-menu {
    &::before {
        content: $cwf-menu;
    }
}

.cwf-notdisplay {
    &::before {
        content: $cwf-notdisplay;
    }
}

.cwf-pause {
    &::before {
        content: $cwf-pause;
    }
}

.cwf-pen {
    &::before {
        content: $cwf-pen;
    }
}

.cwf-pinterest {
    &::before {
        content: $cwf-pinterest;
    }
}

.cwf-search {
    &::before {
        content: $cwf-search;
    }
}

.cwf-sechage {
    &::before {
        content: $cwf-sechage;
    }
}

.cwf-share {
    &::before {
        content: $cwf-share;
    }
}

.cwf-star-empty {
    &::before {
        content: $cwf-star-empty;
    }
}

.cwf-tiktok {
    &::before {
        content: $cwf-tiktok;
    }
}

.cwf-user {
    &::before {
        content: $cwf-user;
    }
}

.cwf-validate {
    &::before {
        content: $cwf-validate;
    }
}

.cwf-youtube {
    &::before {
        content: $cwf-youtube;
    }
}


// ----------------------------------------------------
// LAUNDRY CARE
// ----------------------------------------------------

@font-face {
    font-family: "#{$laundry-care-font-family}";
    src:
        url("#{$laundry-care-font-path}/#{$laundry-care-font-family}.ttf?mwpvlm") format("truetype"),
        url("#{$laundry-care-font-path}/#{$laundry-care-font-family}.woff?mwpvlm") format("woff"),
        url("#{$laundry-care-font-path}/#{$laundry-care-font-family}.svg?mwpvlm##{$laundry-care-font-family}") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

i:not(.fa)[class^="laundry-care-"],
i:not(.fa)[class*=" laundry-care-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "#{$laundry-care-font-family}" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.laundry-care-30 {
    &::before {
        content: $laundry-care-30;
    }
}

.laundry-care-30D {
    &::before {
        content: $laundry-care-30D;
    }
}

.laundry-care-30TD {
    &::before {
        content: $laundry-care-30TD;
    }
}

.laundry-care-40 {
    &::before {
        content: $laundry-care-40;
    }
}

.laundry-care-40D {
    &::before {
        content: $laundry-care-40D;
    }
}

.laundry-care-40TD {
    &::before {
        content: $laundry-care-40TD;
    }
}

.laundry-care-50 {
    &::before {
        content: $laundry-care-50;
    }
}

.laundry-care-50D {
    &::before {
        content: $laundry-care-50D;
    }
}

.laundry-care-60 {
    &::before {
        content: $laundry-care-60;
    }
}

.laundry-care-95D {
    &::before {
        content: $laundry-care-95D;
    }
}

.laundry-care-BX {
    &::before {
        content: $laundry-care-BX;
    }
}

.laundry-care-CL {
    &::before {
        content: $laundry-care-CL;
    }
}

.laundry-care-CLX {
    &::before {
        content: $laundry-care-CLX;
    }
}

.laundry-care-MAIN {
    &::before {
        content: $laundry-care-MAIN;
    }
}

.laundry-care-NSX {
    &::before {
        content: $laundry-care-NSX;
    }
}

.laundry-care-PD {
    &::before {
        content: $laundry-care-PD;
    }
}

.laundry-care-R1P {
    &::before {
        content: $laundry-care-R1P;
    }
}

.laundry-care-R2P {
    &::before {
        content: $laundry-care-R2P;
    }
}

.laundry-care-R3P {
    &::before {
        content: $laundry-care-R3P;
    }
}

.laundry-care-RX {
    &::before {
        content: $laundry-care-RX;
    }
}

.laundry-care-S1P {
    &::before {
        content: $laundry-care-S1P;
    }
}

.laundry-care-S2P {
    &::before {
        content: $laundry-care-S2P;
    }
}

.laundry-care-SAO {
    &::before {
        content: $laundry-care-SAO;
    }
}

.laundry-care-SOF {
    &::before {
        content: $laundry-care-SOF;
    }
}

.laundry-care-SOP {
    &::before {
        content: $laundry-care-SOP;
    }
}

.laundry-care-SP {
    &::before {
        content: $laundry-care-SP;
    }
}

.laundry-care-SSF {
    &::before {
        content: $laundry-care-SSF;
    }
}

.laundry-care-SX {
    &::before {
        content: $laundry-care-SX;
    }
}

.laundry-care-X {
    &::before {
        content: $laundry-care-X;
    }
}
