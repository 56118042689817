//==============================
//* [PARTIAL] HEADER
// Extends app_storefront_base
//? This file imports the header from SFRA stylesheets and custom header CSS.
//==============================
//
// OVERRIDE
// HEADER
//    GENERAL
//    MENU TOGGLE
// TOP BANNER
// MENU BANNER
// BREADCRUMB
//

@import "menu";

// -------------------------------------
// OVERRIDE
// -------------------------------------

$banner-padding: 0.3125em;
$menu-padding: 0.5em;
$menu-item-margin: 0.625em;

.navbar-header {
    height: 4.375em; /* 70/16 */

    .user,
    .country-selector,
    .search,
    .minicart {
        display: inline-block;
        margin: 1.125em 0 0 0.5em;
    }

    .user,
    .country-selector,
    .minicart,
    .navbar-toggler {
        line-height: 2.25em; /* 36/16 */
        height: auto;
        margin: 0;
    }

    .navbar-toggler {
        font-size: 1.6em;
        width: auto;
    }

    .user {
        position: relative;

        .popover {
            position: absolute;
            top: 80%;
            left: 50%;
            display: none;
            transform: translateX(-50%);
            border: none;
            border-radius: 0;
            box-shadow: $box-shadow;
            padding: $input-btn-padding-y $input-btn-padding-x;
            min-width: 120px;
            background-color: $white;

            .nav {
                flex-direction: column;
            }

            li {
                font-size: $font-size-base;
                margin-bottom: calc(#{$input-btn-padding-y} / 2);
            }

            a {
                white-space: nowrap;
            }

            &::before {
                left: 1.5rem;
            }

            &::after {
                left: 1.5rem;
            }

            &.show {
                display: block;
            }
        }
    }

    &.brand-desktop {
        display: none;

        @include media-breakpoint-up(dp) {
            display: block;
            transform: translateY(-1px);
        }
    }

    &.brand {

        @include media-breakpoint-up(dp) {
            width: 14.125em; /* 226/16 */
            margin-left: -7.0625em; /* 113/16 */
        }

        @include media-breakpoint-down(lg) {
            width: 6em; /* 64/16 */
            margin-left: -2em;
        }

        h1 {
            font-size: 0; // Trick for SEO
        }

        img {
            width: 100%;
        }

        &-no-width {
            width: unset;

            @include media-breakpoint-up(dp) {
                transform: translateY(-15px);
            }
        }
    }

    .pull-mobile {
        display: block;

        @include media-breakpoint-up(dp) {
            display: none;
        }
    }
}

.main-menu {
    background-color: $dark-gray;

    .navbar .close-menu button,
    .navbar .close-button button {
        background-color: transparent;
        border: 0;
        appearance: none;
    }
}

.header-banner {
    background-color: $darker-gray;
    text-align: center;
    color: $white;

    .close-button {
        width: 1.5em + $banner-padding * 2;

        .close {
            opacity: 1;
            color: $white;
            width: 100%;
            height: 100%;
            background-color: #{var(--skin-primary-color-1)};
        }
    }

    .content {
        margin-right: 1.5em;
        padding-top: $banner-padding;
        padding-bottom: $banner-padding;
    }
}

.minicart {
    margin-top: 0.1875em; /* 3/16 */
    vertical-align: top;

    .minicart-icon {
        font-size: 1.5em;
    }

    a.minicart-link {
        &:hover {
            text-decoration: none;
        }
    }

    .minicart-quantity {
        background-color: #{var(--skin-primary-color-1)};
        border-radius: 50%;
        width: 1.25em; /* 20/16 */
        height: 1.25em; /* 20/16 */
        line-height: normal;
        display: inline-block;
        text-align: center;
        font-size: 0.8125em; /* 13/16 */
        position: relative;
        top: -0.9375em; /* 15/16 */
        left: -0.9375em; /* 15/16 */
        color: $white;
    }
}

a.normal {
    color: #{var(--skin-primary-color-1)};
    text-decoration: underline;
}

.slide-up {
    transition-duration: 0.5s;
    transition-timing-function: ease-in;
    max-height: 100px;
    overflow: hidden;

    &.hide {
        max-height: 0;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }
}

.dropdown-country-selector {
    margin-top: -0.0625em;
}

.cookie-warning-messaging.cookie-warning {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
    display: none;
}

.valid-cookie-warning {
    background-color: #{var(--skin-link-color-1)};
    color: $white;
    white-space: nowrap;

    p {
        margin-top: 0;
        margin-bottom: 0.2em;
        padding-right: 2em;
    }
}


// -------------------------------------
// HEADER
// -------------------------------------

// GENERAL
// -------------------------------------

header {
    background-color: $white;

    > nav {
        position: relative;
        border-bottom: 1px solid $black;

        @include media-breakpoint-down(lg) {
            border-bottom: 1px solid $horizontal-border-grey;
        }
    }
}

.header-sticky {
    position: sticky;
    transition: top 0.3s ease;
    z-index: 4;
}

.header {
    padding-top: 20px;
    padding-bottom: 15px;

    @include media-breakpoint-up(dp) {
        padding-bottom: 0;
        padding-top: 20px;
    }

    &-simplified {
        padding-bottom: 0;
    }

    > .header-flex {
        @include media-breakpoint-up(dp) {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        &-simplified {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 15px;

            @include media-breakpoint-up(dp) {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }

    .header-left {
        @include media-breakpoint-up(dp) {
            display: flex;
            gap: 35px;
        }
    }

    &__nomenu {
        @include media-breakpoint-down(sm) {
            border-bottom: 1px solid $grey3;
        }

        @include media-breakpoint-up(lg) {
            margin-top: #{$grid-gutter-width};
            margin-bottom: calc(#{$grid-gutter-width} * 1.5);
        }

        .logo-home {
            display: block;
            margin-top: calc(#{$grid-gutter-width} / 3);
            margin-bottom: calc(#{$grid-gutter-width} / 2);

            @include media-breakpoint-up(md) {
                margin-top: 0;
                margin-bottom: 0;
            }

            img {
                width: auto;
                height: 20px;

                @include media-breakpoint-up(sm) {
                    height: 40px;
                }

                @include media-breakpoint-up(md) {
                    height: 60px;
                }
            }
        }
    }
}

.navbar-header {
    height: auto;
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-up(lg) {
        .pull-left,
        .navbar-header,
        .pull-right {
            width: 33%;
        }

        .navbar-header {
            text-align: center;
        }
    }

    a {
        text-decoration: none;
    }

    &__right {
        @include make-transition(opacity);

        opacity: 1;

        @include media-breakpoint-up(lg) {
            display: flex;
            justify-content: flex-end;
        }

        &.opacified {
            opacity: 0;
        }
    }

    .search-header,
    .minicart,
    .navbar-toggler,
    .my-list,
    .my-account {
        display: inline-block;
        margin: 0;
        padding: 0;
        cursor: pointer;
        line-height: 1;
        color: $black;
        font-size: $font-size-h2-mobile;

        @include media-breakpoint-up(dp) {
            padding-left: #{$grid-gutter-width};
        }

        a {
            color: $black;
        }
    }

    .wishlist {
        font-size: $font-size-h3-desktop;
    }

    .search-header {
        margin-right: calc(#{$grid-gutter-width} / 1.5);

        @include media-breakpoint-up(lg) {
            margin-right: #{$grid-gutter-width};
        }

        &:hover {
            text-decoration: none;
        }
    }

    &.brand {
        margin-left: 0;

        img {
            width: auto;
            height: 20px;

            @include media-breakpoint-up(dp) {
                height: 33px;
            }
        }
    }

    &__right {
        display: flex;
        align-items: center;
        transform: translateY(-4px);

        @include media-breakpoint-up(lg) {
            transform: translateY(-6px);
            position: relative;
            z-index: 10;
        }

        a {
            &:hover {
                text-decoration: none;
            }
        }

        i.active {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                right: 0;
                top: 0;
                background-color: $black;
                width: 6px;
                height: 6px;
                border-radius: 50%;
            }
        }
    }

    &__left {
        text-transform: uppercase;
        font-size: $font-size-sm;

        ul {
            display: flex;
            padding-left: 0;
            list-style-type: none;

            li {
                margin-right: calc(#{$grid-gutter-width} / 1.5);

                &:first-child {
                    margin-right: calc(#{$grid-gutter-width} / 3);
                }
            }
        }
    }
}

// MENU TOGGLE
// -------------------------------------

.container.dropdown-mobile {
    @include media-breakpoint-up(lg) {
        max-width: 100%;
    }
}

.nav-item__img {
    height: 30px;
    position: absolute;
    top: 8px;

    @include media-breakpoint-down(lg) {
        top: -4px;
    }

    + span {
        padding-left: 35px;
    }
}

div.dropdown-menu > ul {
    padding-left: 0;
}

.menu-toggleable-left {
    &.in {
        width: 100%;
        margin-right: 0;
        overflow-x: auto;
    }

    .nav-item,
    .dropdown-item {
        border-top: none;

        @include media-breakpoint-down(lg) {
            padding: calc(#{$grid-gutter-width} / 1.5) calc(#{$grid-gutter-width} / 2);
        }

        + .nav-item {
            border-top: none;
        }

        a {
            position: relative;
            padding: 0;
            text-decoration: none;
        }

        i {
            @include element-centered-Y;

            right: 0;
            transform: translateY(-50%) rotate(-90deg);
            font-size: $font-size-h2-desktop;
        }

        &.top-category {
            text-align: center;
            border-bottom: 1px solid $black;
            margin-bottom: calc(#{$grid-gutter-width} / 1.5);

            i {
                display: none;
            }
        }

        &:hover {
            background-color: $white;
        }

        .nav-link {
            padding-left: 0;
        }
    }

    .row {
        position: initial;
    }

    .navbar {
        position: initial;
    }

    .dropdown-menu {
        right: 0;
        bottom: 0;
        background-color: $white;
        overflow: auto;

        .close-button,
        .navbar-header.brand {
            display: none;
        }
    }

    .back {
        position: absolute;
        left: 30px;
        bottom: -20px;
        z-index: 1;
        transform: translateY(100%);

        i {
            transform: translateY(-50%) rotate(90deg);
        }
    }

    &.main-menu {
        background-color: $white;

        .navbar-nav {
            justify-content: center;
            gap: 20px;
            text-transform: uppercase;
            margin-top: 3px;

            @include media-breakpoint-down(lg) {
                ul {
                    > .main-category {
                        &:not(:last-child) {
                            border-bottom: 1px solid $black;
                        }

                        > a {
                            font-weight: $font-weight-bold;

                            i {
                                display: none;
                            }

                            + .dropdown-menu {
                                position: initial !important; // override nativ position
                                padding-top: 0;
                                padding-bottom: 0;

                                > ul > .dropdown-item {
                                    padding-left: 0;
                                    padding-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }

            .nav-link {
                color: $black;
                font-weight: bold;

                @include media-breakpoint-up(dp) {
                    position: relative;
                    padding-bottom: 25px;
                }
            }

            a {
                text-decoration: none;
                position: relative;

                @include media-breakpoint-up(dp) {
                    font-weight: $font-weight-bold;
                }
            }
        }
    }

    .close-menu {
        position: relative;
        height: 44px;
        padding: calc(#{$grid-gutter-width} / 3) calc(#{$grid-gutter-width} / 2) calc(#{$grid-gutter-width} / 7.5);
        border-bottom: none;

        i {
            font-size: $font-size-h2-desktop;
        }

        img {
            max-height: 21px;
        }

        button {
            padding: 0;
        }

        > .navbar-header.brand {
            z-index: 1001;
        }
    }

    .navbar {
        @include media-breakpoint-down(lg) {
            border-bottom: 1px solid $black;
        }

        &-header {
            top: calc(#{$grid-gutter-width} / 3);
        }
    }

    .menu-toggle-more {
        padding: calc(#{$grid-gutter-width} / 1.5) calc(#{$grid-gutter-width} / 2);

        &__join-us {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            margin-top: calc(#{$grid-gutter-width} / 1.2);

            a {
                min-width: 150px;
                margin: calc(#{$grid-gutter-width} / 3) 0;
            }
        }

        &__delivery {
            display: flex;
            margin-bottom: calc(#{$grid-gutter-width} / 0.857);
            text-transform: uppercase;

            span {
                margin-right: calc(#{$grid-gutter-width} / 3);
                font-size: $font-size-sm;

                &:first-child {
                    margin-right: calc(#{$grid-gutter-width} / 6);
                }
            }
        }

        &__contact {
            text-decoration: none;
            text-transform: uppercase;
            font-size: $font-size-sm;
        }

        ul {
            margin-top: calc(#{$grid-gutter-width} / 1.5);
            margin-bottom: calc(#{$grid-gutter-width} / 0.667);
            padding: 0;
            list-style-type: none;
            text-transform: uppercase;

            li {
                margin: calc(#{$grid-gutter-width} / 3) 0;
            }

            a {
                display: inline-flex;
                text-decoration: none;
            }

            i {
                padding-right: calc(#{$grid-gutter-width} / 2);
                font-size: $font-size-h3-desktop;

                &.cwf-star-empty {
                    font-size: 1.1rem;
                }

                &.cwf-cart,
                &.cwf-search {
                    transform: translateX(-1px);
                }
            }
        }
    }

    .dropdown-toggle::after {
        content: none;
    }
}

@include media-breakpoint-up(dp) {
    header {
        .top-category {
            display: none;
        }

        .menu-group {
            .navbar-nav > .nav-item {
                #brands,
                #outlet {
                    + .dropdown-menu ul {
                        display: grid;
                        grid-template-columns: repeat(4, 1fr);

                        li {
                            flex-basis: calc(100% / 4);
                            width: calc(100% / 4);
                            padding-left: 0;
                        }
                    }
                }

                &.dropdown > .dropdown-menu,
                .dropdown-menu > li:not(.dropdown-item) .dropdown-menu {
                    position: absolute;
                    top: inherit;
                    right: 0;
                    bottom: -1px;
                    left: 0;
                    padding: calc(#{$grid-gutter-width} / 0.6) calc(#{$grid-gutter-width} / 0.429) calc(#{$grid-gutter-width} / 0.429);
                    margin: 0;
                    transform: translateY(100%);
                    box-shadow: 0 2px 2px -2px $black;
                    font-size: $font-size-sm;
                    width: 100vw;

                    > ul {
                        display: flex !important; //override boostrap style

                        > li {
                            position: relative;
                            font-size: $font-size-base;

                            > .dropdown-menu > ul > .dropdown-item {
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }

            .dropdown-item .dropdown-menu {
                position: initial;
                display: block;
                transform: none;
                box-shadow: none;
                font-size: $font-size-sm;

                .dropdown-menu {
                    padding-left: $grid-gutter-width;
                }
            }

            .dropdown-item .dropdown-menu .dropdown-item {
                padding: 0;
                text-transform: capitalize;

                @include media-breakpoint-up(lg) {
                    img {
                        top: 0;
                    }
                }

                a {
                    font-weight: normal;
                }
            }
        }

        li,
        .dropdown {
            position: initial;
        }

        .main-menu {
            .cwf-dropdown {
                display: none;
            }
        }

        // don't display n-4 on desktop
        .navbar-nav .dropdown-item.dropdown .dropdown-item.dropdown .dropdown-item.dropdown .dropdown-menu {
            display: none;
        }
    }
}


// -------------------------------------
// TOP BANNER
// -------------------------------------

.header {
    &-top-banner {
        background-color: $black;
        padding: 10px 0;

        &.light {
            color: $black;
        }

        &.dark {
            color: $white;
        }

        &__container {
            display: flex;
            overflow: hidden;
        }

        &__message {
            display: inline-block;
            padding: 0 $grid-gutter-width;
            text-align: center;
            font-size: $font-size-sm;

            @include media-breakpoint-up(lg) {
                padding: 0;
                width: 100vw;
            }
        }
    }
}


// -------------------------------------
// OVERRIDE
// -------------------------------------

.categoryBannerHeaderActivated {
    display: flex;
    flex-direction: column;
    margin-left: $input-btn-padding-x;
    margin-right: $input-btn-padding-x;

    @include media-breakpoint-up(dp) {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        margin-left: 0;
        margin-right: 0;
    }

    > ul {
        padding-left: 0;

        @include media-breakpoint-up(dp) {
            display: flex;
            flex-grow: 1;
            justify-content: space-between;
        }

        > li {
            font-size: $font-size-base;
        }
    }
}

.categoryBannerHeader {
    position: relative;
    overflow: hidden;
    margin-right: $input-btn-padding-x;
    margin-left: $input-btn-padding-x;
    border-radius: $border-radius-lg;
    order: 2;
    max-width: 100%;


    @include media-breakpoint-up(md) {
        margin-bottom: 0;
    }


    @include media-breakpoint-up(dp) {
        order: 1;
    }

    img {
        max-width: 100%;

        @include media-breakpoint-down(dp) {
            width: 100%;
        }
    }

    &__content {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: $grid-gutter-width;
        color: $white;

        span {
            display: block;
            margin-bottom: $grid-gutter-width / 2;
            font-size: $font-size-h3-mobile;
            font-weight: $font-weight-bold;
        }

        p {
            margin-bottom: $grid-gutter-width / 2;
        }

        a {
            padding: 12px !important;
            border: 1.5px solid $white;
            border-radius: 3px;
            color: $white;

            &:hover {
                color: $white;
                background-color: $black;
                border-color: $black;
                opacity: 1;
            }

            &:nth-child(n + 1) {
                margin-top: 5px;
            }
        }
    }
}


// -------------------------------------
// BREADCRUMB
// -------------------------------------

.breadcrumb {
    &__container {
        position: relative;
        background-color: $white;
        text-align: center;

        @include media-breakpoint-up(lg) {
            text-align: left;
        }
    }

    &__list {
        margin: 10px 0;

        @include media-breakpoint-up(lg) {
            margin: $grid-gutter-width 0;

            .floating-breadcrumbs & {
                position: absolute;
                z-index: 1;
            }
        }
    }

    &__item {
        display: inline-block;
        text-transform: uppercase;
        font-size: $font-size-sm;

        a {
            text-decoration: none;
        }

        &:not(:last-child) a {
            font-weight: normal !important; // !override native style
        }

        &--current {
            font-weight: $font-weight-bold;
        }

        &:not(:first-child) {
            &::before {
                content: "/";
            }
        }
    }
}
