// ==============================
// * WISHLIST GLOBAL
// Native. Don't use.
// ==============================

.add-to-wishlist-messages {
    position: fixed;
    z-index: 1000;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);

    a {
        margin-left: 5px;
    }
}

.add-to-wishlist-alert {
    padding: 1em;
    animation: fade 5s linear forwards;
    box-shadow: 1px 1px 5px $grey6;

    @keyframes fade {
        0% { opacity: 0; }
        10% { opacity: 1; }
        90% { opacity: 1; }
        100% { opacity: 0; }
    }

    &.show {
        display: block;
    }
}

.wishlistTile {
    position: absolute;
    top: 8px;
    right: 16px;

    i {
        &.fa-heart-o {
            color: rgba(0, 0, 0, 0.7);
        }

        &.fa-circle {
            color: rgba(255, 255, 255, 0.7);
            text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        }
    }
}

.wishlistTile {
    .fa-heart {
        color: $black;
    }
}
