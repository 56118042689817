// ==============================
// * [PARTIAL] FOOTER
// Extends app_storefront_base
// ? This file gathers all the custom footer CSS.
// ==============================

// FOOTER
//    GENERAL
//    STORE DELIVERY


@import "base/components/footer";

// -------------------------------------
// Footer
// -------------------------------------

// GENERAL
// -------------------------------------

.footer-main {
    overflow: hidden;
    padding: 40px 0;
    background-color: $black;

    @include media-breakpoint-up(lg) {
        padding: 70px 0 60px;
    }

    &,
    * {
        font-size: rem(12px);
        color: $white;

        a {
            text-decoration: none;

            &:hover,
            &:focus {
                text-decoration: underline;
                color: $white;
            }

            &.footer-main__social-media__link {
                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }
        }
    }

    &__title,
    h3 {
        position: relative;
        margin: 20px 0;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: $font-weight-normal;

        @include media-breakpoint-up(md) {
            margin: 0 0 rem(26px);
        }

        @include media-breakpoint-up(md) {
            cursor: initial;
        }

        &::before {
            content: $cwf-dropdown;
            position: absolute;
            top: 50%;
            right: 0;
            display: inline-block;
            vertical-align: middle;
            transform: translateY(-50%) rotate(-90deg);
            font-size: rem(25px);
            line-height: 1;
            font-family: "#{$cwf-font-family}" !important;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
            text-transform: none;

            @include media-breakpoint-up(md) {
                display: none;
            }
        }
    }

    &__columns {
        > div {
            display: flex;
            flex-flow: column wrap;
            align-items: flex-start;
            justify-content: flex-start;

            @include media-breakpoint-up(md) {
                flex-flow: row wrap;
            }
        }
    }

    &__column {
        &:not(.footer-main__store-delivery) {
            flex: 0 1 25%;
            margin-bottom: 20px;
            width: 100%;

            &:nth-child(1) {
                .footer-main__title {
                    margin-top: 0;
                }
            }

            ul {
                li {
                    line-height: 18px;
                }
            }

            .js-panel {
                display: none;

                @include media-breakpoint-up(md) {
                    display: block;
                    opacity: 1;
                    padding-right: 20px;
                }
            }

            &.show {
                .footer-main__title {
                    &::before {
                        transform: translateY(-50%) rotate(0deg);
                    }
                }
            }

            &.column-x2 {
                flex: 0 1 50%;
            }
        }

        &.footer-main__store-delivery {
            flex: 0 1 100%;
            margin-bottom: 20px;
            width: 100%;
            z-index: 1;

            &:nth-child(1) {
                .footer-main__title {
                    margin-top: 0;
                }
            }

            ul {
                li {
                    line-height: 18px;
                }
            }

            .js-panel {
                display: none;
            }

            &.show {
                .footer-main__title {
                    &::before {
                        transform: translateY(-50%) rotate(0deg);
                    }
                }
            }

            &.column-x2 {
                flex: 0 1 50%;
            }
        }
    }

    &__text {
        margin-top: 20px;

        @include media-breakpoint-up(sm) {
            margin-top: 0;
        }

        .footer-main__title,
        h3 {
            &::before {
                display: none;
            }
        }
    }

    &__social-media {
        margin-bottom: 20px;

        @include media-breakpoint-up(sm) {
            margin-bottom: 0;
        }

        > div,
        &__list {
            width: 100%;
        }

        &__list {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: justify;
            margin-bottom: 0;
            padding: 0;
            width: 100%;

            @include media-breakpoint-up(sm) {
                justify-content: center;
            }

            @include media-breakpoint-up(lg) {
                justify-content: flex-start;
            }

            li {
                margin: 0 10px;

                @include media-breakpoint-up(md) {
                    margin: 0;
                }

                @include media-breakpoint-up(lg) {
                    margin: 0 10px;
                }

                &:nth-child(1) {
                    margin-left: 0;
                }

                &:nth-last-child(1) {
                    margin-right: 0;
                }

                a {
                    @include make-transition(opacity 0.2s ease-in-out);
                    @include make-icon(rem(32px));

                    font-size: 0;
                    overflow: hidden;
                    text-decoration: none;

                    &:hover {
                        text-decoration: none;
                        opacity: 0.7;
                    }
                }
            }
        }
    }

    &__email {
        &__group {
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-start;

            @include media-breakpoint-up(md) {
                flex-flow: row nowrap;
                align-items: center;
                justify-content: space-between;
            }
        }

        &__signup {
            margin-bottom: 35px;

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
            }
        }

        &__description {
            margin-bottom: 20px;
            font-weight: $font-weight-bold;
            text-transform: uppercase;

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
            }
        }

        &__input {
            display: flex;
            width: 100%;

            @include media-breakpoint-up(md) {
                margin-left: 70px;
                max-width: 50%;
            }

            input[type="text"],
            input[type="mail"],
            button[type="submit"] {
                border: 1px solid $white;
                border-radius: $border-radius-sm 0 0 $border-radius-sm;
                color: $white;
            }

            input[type="text"],
            input[type="mail"] {
                border-width: 1px 0 1px 1px;
                width: calc(100% - 50px);
                padding: 0 $input-btn-padding-x;
                font-size: rem(12px);
                line-height: 50px;
            }

            button[type="submit"] {
                width: 50px;
                background: transparent;
                border-width: 1px 1px 1px 0;
                border-radius: 0 $border-radius-sm $border-radius-sm 0;
            }
        }
    }

    &__light-mode {
        border-top: 1px solid $black;
        background-color: $white;

        &,
        * {
            color: $black;
        }

        a {
            &:hover,
            &:focus {
                color: $black;
                text-decoration: underline;
            }
        }

        .footer-main__column {
            &.inverse-bkg {
                background-color: $black;

                &,
                * {
                    color: $white;
                }
            }
        }

        .footer-main__email {
            &__input {
                input[type="text"],
                input[type="mail"],
                button[type="submit"] {
                    border-color: $black;
                }
            }
        }
    }


    // STORE DELIVERY
    // -------------------------------------

    &__store-delivery {
        display: flex;
        flex-flow: row nowrap;
        transform: translateY(-1px);
        width: 100%;
        text-transform: uppercase;
        font-size: $font-size-sm;

        > ul {
            display: flex;
            width: 100%;
            padding-left: 0;
            list-style-type: none;

            > li {
                &:nth-child(1) {
                    line-height: 1.8;
                }
            }
        }

        .allowed-locales {
            z-index: 1;
            display: flex;
            flex-flow: column nowrap;
            justify-content: flex-end;
            align-items: flex-end;
            background: transparent;
            padding-right: $input-btn-padding-y;

            &,
            > {
                width: 100%;
            }

            .flex-fill {
                display: inline-block;
                width: 22px;
            }

            a {
                &:hover {
                    text-decoration: none;
                }
            }
        }

        .dropdown {
            p,
            li {
                position: relative;
                z-index: 100;
                margin-bottom: 0;
                width: 70%;

                &.store-delivery__item {
                    width: 100%;

                    &:nth-child(1) {
                        margin-bottom: 3px;
                    }
                }
            }

            .sub-menu__btn,
            .btn {
                display: flex;
                flex-flow: row nowrap;
                align-items: flex-start;
                justify-content: flex-start;
                background-color: $black;
                width: 100%;
                line-height: 1.8;

                i {
                    transform: translateY(-1px) rotate(-90deg);
                    transition: transform 0.2s ease;
                    margin: 0;
                    font-size: rem(25px);

                    &::before {
                        color: $white;
                    }
                }

                &::after,
                &::before {
                    display: none;
                }
            }

            &.show,
            &:hover {
                color: $white;

                > .btn {
                    color: $white;

                    i {
                        transform: translateY(-1px) rotate(0deg);
                    }
                }
            }

            &-menu {
                left: -15px;
                margin: 0;
                padding: 0 $input-btn-padding-x 0 $input-btn-padding-x;
                background-color: $black;
                width: 100%;

                ul {
                    margin: 0;
                    padding: 0;

                    li {
                        line-height: 1.8;
                    }
                }
            }

            &.sub-menu {
                .nowrap {
                    white-space: nowrap;
                }

                .dropdown-menu {
                    top: 98%;
                    right: 0;
                    left: auto;
                }

                &.show,
                &:hover {
                    color: $white;

                    &,
                    &::before {
                        background: transparent;
                    }

                    .sub-menu__btn {
                        color: $white;

                        &,
                        &:hover {
                            text-decoration: none;
                        }

                        i {
                            transform: translateY(-1px) rotate(0deg);

                            &::before {
                                background: transparent;
                            }
                        }
                    }
                }
            }
        }
    }

    .dropdown-country-selector {
        max-height: 90px;
        overflow-y: scroll;

        @include media-breakpoint-up(md) {
            max-height: 120px;
        }
    }
}
