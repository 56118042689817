// ==============================
// * [PARTIAL] BOOTSTRAP OVERRIDES
// Extends app_storefront_base
// ? This file gathers all the Bootstrap styles overrides.
// ==============================
//
// BODY
// FONTS
//    TITLES
//    WEIGHTS
// INPUTS
// LINKS
// BUTTONS
//    DROPDOWN
// ALERTS
// HR
// MODALS
// PRODUCT LABELS
// VISIBILITY
// TOAST MESSAGE (NOTIFICATION)
//

@import "base/bootstrapOverrides";
@import "bootstrap/scss/mixins/breakpoints";


// -------------------------------------
// BODY
// -------------------------------------

body {
    &.no-scroll {
        overflow: hidden;
    }
}


// -------------------------------------
// FONTS
// -------------------------------------

// TITLES
// -------------------------------------

.h1,
.h2,
.h3 {
    display: block;
}

h1,
.h1 {
    @include h1;

    &.big {
        @include big-h1;
    }
}

h2,
.h2 {
    @include h2;
}

h3,
.h3 {
    @include h3;
}

// WEIGHTS
// -------------------------------------

bold {
    font-weight: $font-weight-bold;
}

// -------------------------------------
// INPUTS
// -------------------------------------

input[type="text"],
input[type="search"],
input[type="email"],
input[type="tel"],
input[type="password"],
input[type="number"] {
    @include appearance-none;

    padding: 12px 12px 5px;
    border-bottom: 1px solid $black;
    background-color: transparent;
    color: $black;
}

textarea {
    @include appearance-none;

    padding: 12px;
    overflow: auto;
    resize: none;
    border: 1px solid $black;
    border-radius: $border-radius-sm;
}

input[type="radio"] {
    @include appearance-none;

    position: relative;
    min-width: 18px;
    width: 18px;
    height: 18px;
    border: 1px solid $black;
    border-radius: $border-radius-xl;

    &:checked {
        &::after {
            @include element-centered-X-Y;

            content: "";
            width: 10px;
            height: 10px;
            border-radius: 52px;
            background-color: $black;
        }
    }
}

input[type="checkbox"] {
    @include appearance-none;

    position: relative;
    display: grid;
    place-content: center;
    width: 18px;
    height: 18px;
    border: 1px solid $black;
    border-radius: 2px;
    background-color: $white;

    &::before {
        @include make-transition(transform, 0.1s);

        content: "";
        width: rem(10px);
        height: rem(10px);
        background-color: $black;
        transform-origin: bottom left;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        transform: scale(0);
    }

    &:checked {
        &::before {
            transform: scale(1);
        }
    }

    &.disabled {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.5;

        + label {
            cursor: not-allowed;
            pointer-events: none;
            opacity: 0.5;
        }
    }
}

select,
.custom-select,
.form-control.custom-select {
    @include appearance-none;

    min-width: 100px;
    padding: 5px 12px;
    border: 1.5px solid $black;
    border-radius: 3px;
    background-image: url("../images/dropdown.svg") !important; // !important is used here to override Bootstrap style
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: right;
    background-size: 25px;
    cursor: pointer;

    &.is-invalid {
        padding-right: 12px !important;
        background-image: url("../images/dropdown.svg") !important; // !important is used here to override Bootstrap style
        background-repeat: no-repeat;
        background-position-y: center;
        background-position-x: right;
        background-size: 25px;
    }

    &[disabled],
    &.disabled {
        background-image: none !important; // !important is used here to override select neutral state's !important
        color: $grey-disabled;
        border-color: $grey-disabled;
        pointer-events: none;
    }
}


// -------------------------------------
// LINKS
// -------------------------------------

a {
    @include make-transition($property: opacity);

    color: inherit;
    text-decoration: underline;

    &:hover {
        color: inherit;
        opacity: 0.7;
    }
}


// ---------------------------------------------
// BUTTONS
// ---------------------------------------------

.btn {
    @include appearance-none;
    @include make-transition;

    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: solid 1px transparent;
    border-radius: 3px;
    padding: $input-btn-padding-y $input-btn-padding-x;
    background-color: transparent;
    font-size: rem(12px);
    font-weight: $font-weight-bold;
    text-decoration: none;
    text-transform: uppercase;
    white-space: $btn-white-space;
    color: $body-color;

    i {
        transform: translateY(-1px);
        margin-left: 12px;
        font-size: rem(18px);
    }

    &-primary {
        @include button-variant($black, $black, $hover-background: $black, $hover-border: $black, $active-background: $black, $active-border: $black);

        i {
            &::before {
                color: $white;
            }
        }

        &:hover,
        &:focus,
        &:active,
        &.active {
            opacity: 0.8;
            border-color: $primary;
            background-color: $primary;
            color: $white;
        }
    }

    &-outline-primary {
        @include button-variant($white, $black, $hover-background: $white, $hover-border: $black, $active-background: $black, $active-border: $black);

        i {
            &::before {
                color: $black;
            }
        }

        &:hover,
        &:focus,
        &:active,
        &.active {
            opacity: 0.8;
            border-color: $primary;
            background-color: $primary;
            color: $white;
        }
    }

    &-secondary {
        @include button-variant($white, $black, $hover-background: $white, $hover-border: $black, $active-background: $white, $active-border: $black);

        i {
            &::before {
                color: $black;
            }
        }

        &:hover,
        &:focus,
        &:active,
        &.active {
            opacity: 0.8;
            border-color: $primary;
            background-color: $primary;
            color: $white;
        }
    }

    &-outline {
        @include button-variant(transparent, $black, $hover-background: transparent, $hover-border: $black, $active-background: transparent, $active-border: $black);

        i {
            &::before {
                color: $black;
            }
        }

        &:hover,
        &:focus,
        &:active,
        &.active {
            border-color: $primary;
            background-color: $secondary;
            color: $black;
            opacity: 0.8;
        }
    }

    &-reverse {
        background-color: transparent;
        box-shadow: 0 0 0 1px $primary;
        border-color: $black;

        &:hover,
        &:focus,
        &:active,
        &.active {
            background-color: transparent;
            border-color: $black;
            color: $black;
        }
    }

    &-link {
        text-transform: none;
        text-decoration: underline;

        i {
            text-decoration: none;
        }

        &:hover,
        &:focus,
        &:active {
            text-decoration: underline;
        }
    }

    &.sized {
        min-width: 240px;
    }

    &.disabled,
    &[disabled] {

        opacity: 1;
        pointer-events: none;
        color: $grey6;

        &:not(.btn-link) {
            background-color: $grey-disabled;
            border-color: $grey-disabled;
        }

        &.btn-link {
            background-color: transparent;
            border: none;
        }
    }

    &.full-width {
        width: 100%;
    }
}

.close {
    @include make-transition(opacity 0.2s ease);
    @include font-size($close-font-size);

    align-items: center;
    justify-content: flex-start;
    float: none;
    display: flex;
    opacity: 1;
    line-height: 1;
    font-weight: $close-font-weight;
    text-shadow: none;
    color: $close-color;

    // Override <a>'s hover style
    @include hover() {
        text-decoration: none;
        color: $close-color;
    }

    &:not(:disabled):not(.disabled) {
        @include hover-focus() {
            opacity: 0.7;
        }
    }
}

// DROPDOWN
// ---------------------------------------------

.dropdown {
    button {
        @include appearance-none;

        background-color: $white;

        &::after {
            display: none;
        }
    }
}

.dropdown-menu {
    border-radius: $border-radius-sm;

    .dropdown-item {
        color: $black;

        &:focus {
            color: $white;
        }
    }
}


// -------------------------------------
// ALERTS
// -------------------------------------

.alert {
    padding: 0;

    &.alert-danger {
        background-color: transparent;
        border: 0;
        color: $danger;
    }
}


// -------------------------------------
// HR
// -------------------------------------

hr {
    margin: 10px 0;
}


// -------------------------------------
// MODALS
// -------------------------------------

.modal {
    &-content {
        border-radius: 0;
        border: none;
        box-shadow: $box-shadow;
        font-size: $font-size-base;
    }

    &-header,
    &-footer {
        border: 0;
    }

    .close {
        @include make-transition(opacity);

        opacity: 1;
        font-size: rem(25px);

        &:hover {
            opacity: 0.7;
        }
    }
}


// -------------------------------------
// PRODUCT LABELS
// -------------------------------------

.product-labels {
    li {
        padding: 4px;
        border: 1px solid $grey3;
        border-radius: $border-radius-sm;
        text-transform: uppercase;
        font-size: rem(10px);

        @include media-breakpoint-up(lg) {
            font-size: $font-size-sm;

            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }
}

// -------------------------------------
// VISIBILITY
// -------------------------------------

.visually-hidden {
    position: absolute !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    border: 0 !important;
}

// ----------------------------------------------------
// TOAST MESSAGE (NOTIFICATION)
// ----------------------------------------------------

.add-to-cart-messages,
.secure-email-sent {
    z-index: 1000;
}

.add-to-cart-messages .alert,
.add-to-wishlist-alert,
.remove-from-wishlist-alert,
.copy-link-message .alert,
.contact-us-signup-message,
.contact-us-signup-alert,
.secure-email-sent .alert,
.email-signup-message .email-signup-alert {
    @include notification();
}

.copy-link-message {
    top: 140px !important; //!important to override bootstrap style
    right: 15px !important; //!important to override bootstrap style
    left: auto !important; //!important to override bootstrap style
    z-index: 1000;
    transform: none !important; //!important to override bootstrap style

    a {
        display: block;
        margin-left: 0;
        text-align: left;
        text-decoration: underline;
    }
}
