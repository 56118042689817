@each $size in map-keys($grid-breakpoints) {

    @include media-breakpoint-down($size) {
        .collapsible-#{$size} {
            .title {

                @include clearfix;
                line-height: 2.5rem; /* 40/16 */

                &::after {
                    float: right;
                    content: "\f078";
                    font-family: "FontAwesome";
                }
            }

            .content,
            .card-body {
                display: none;
            }

            &.active {
                .card-header {
                    i {
                        transform: rotate(180deg);
                    }
                }

                .content,
                .card-body {
                    display: block;
                }
            }
        }
    }

    .container {
        div.collapsible-#{$size} {
            button.title {
                color: $black;
                text-decoration: none;
                border: none;
                background-color: transparent;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}
