// ==============================
// * [PARTIAL] REINSURANCE BLOCK
// Custom file
// ? This file gathers all the custom reinsurance block CSS.
// ==============================

.reinsurance-block {
    &__container {
        border-top: 1px solid $grey4;
        border-bottom: 1px solid $grey4;
        margin: $grid-gutter-width * 2 auto;

        a {
            text-decoration: none;
        }

        img {
            max-width: 48px;
        }

        .container {
            display: grid;
            grid-gap: $grid-gutter-width / 2;
            grid-template-columns: repeat(auto-fit, minmax(calc(50% - 20px), 1fr));
            padding: 30px 10px 14px;

            @include media-breakpoint-up(md) {
                display: flex;
                justify-content: space-between;
            }
        }
    }

    &__item {
        &-wrapper {
            margin: 0 auto;
            text-align: center;
        }
    }

    &__title {
        margin-bottom: 0;
        text-transform: uppercase;
        font-weight: $font-weight-black;

        @include media-breakpoint-up(lg) {
            font-size: $font-size-h3-mobile;
        }
    }

    &__description {
        margin-bottom: 0;
        font-size: $font-size-sm;
    }
}
