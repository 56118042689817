// ==============================
// * [PARTIAL] HEADER SEARCH
// Extends app_storefront_base
// ? This file gathers all the custom header search CSS.
// ==============================
//
// SEARCH BANNER
// SUGGESTIONS
// SEARCH HEADER PLP

@import "base/components/headerSearch";


// -------------------------------------
// SEARCH BANNER
// -------------------------------------

.search {
    position: absolute;
    top: 46px;
    left: 0;
    z-index: 3;
    display: none;
    width: 100%;
    height: 100vh;
    background-color: $white;

    @include media-breakpoint-up(lg) {
        top: auto;
        bottom: 0;
        height: auto;
    }

    form {
        position: relative;
        max-width: 1180px;
        margin: auto;
        margin-top: 20px;
    }

    .close-search-header {
        position: absolute;
        top: -55px;
        right: 0;
        cursor: pointer;
        font-size: rem(25px);

        @include media-breakpoint-up(lg) {
            top: -4px;
        }

        &,
        &:hover {
            text-decoration: none;
        }
    }

    .site-search {
        position: absolute;
        top: 10px;
        right: 0;
        width: 100%;
        height: auto;
        margin: 0 auto;
        padding-top: $grid-gutter-width / 2;
        background-color: $white;

        @include media-breakpoint-up(lg) {
            top: 1px;
        }
    }

    .search-field {
        text-transform: uppercase;
        padding: 0 70px 20px 0;

        @include media-breakpoint-up(lg) {
            padding-top: 5px;
        }
    }

    button[type="submit"] {
        @include appearance-none;

        position: absolute;
        top: -3px;
        right: 0;

        @include media-breakpoint-up(lg) {
            right: 35px;
            top: 1px;
        }

        i {
            font-size: rem(25px);
        }
    }


    // -------------------------------------
    // SUGGESTIONS
    // -------------------------------------

    .suggestions-wrapper {
        @include media-breakpoint-down(lg) {
            height: calc(100vh - 238px);
            overflow-y: auto;
        }

        &,
        &--einstein {
            position: absolute;
            left: 0;
            width: 100vw;
            background-color: $white;
        }
    }

    .suggestions {
        position: relative;
        width: 100%;
        margin: 0 auto;
        border: 0;
        font-size: $font-size-base;

        @include media-breakpoint-up(lg) {
            border-bottom: 1px solid $slightly-darker-gray;
        }

        .category-parent,
        .name {
            color: inherit;
            font-weight: normal;
            font-size: $font-size-sm;
        }

        &__container {
            width: 100%;

            @include media-breakpoint-up(lg) {
                max-width: $search-container-max-width;
            }

            @include media-breakpoint-up(xl) {
                padding: 0 0 $grid-gutter-width;
            }

            ul:not(.suggestions__container--products) {
                .items {
                    padding-left: $grid-gutter-width / 2;
                }
            }

            .header {
                height: auto;
                border: 0;
                color: inherit;
                text-transform: uppercase;
            }

            &--suggest {
                width: 100%;
            }

            &--products {
                margin-top: $grid-gutter-width;

                @include media-breakpoint-up(lg) {
                    .item:not(:last-child) {
                        margin-right: $grid-gutter-width / 2;
                    }
                }

                .list {
                    padding-left: $grid-gutter-width / 2;

                    @include media-breakpoint-up(lg) {
                        display: flex;
                        padding-left: 0;
                    }
                }

                .product {
                    display: flex;
                    align-items: center;

                    @include media-breakpoint-up(lg) {
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    &__thumbnail {
                        width: 60px;
                        padding: 10px 0;
                        background-color: $light-gray;
                        border-radius: $border-radius-lg;

                        @include media-breakpoint-up(lg) {
                            width: 260px;
                            padding: $grid-gutter-width 0;
                        }

                        img {
                            width: 100%;
                            aspect-ratio: 2/2;
                            object-fit: contain;
                        }
                    }

                    &__name {
                        width: 100%;
                        padding-left: $grid-gutter-width / 2;
                        text-transform: uppercase;

                        @include media-breakpoint-up(lg) {
                            max-width: 260px;
                        }

                        @include media-breakpoint-up(lg) {
                            padding: 0;
                            margin-top: $grid-gutter-width / 2;
                        }
                    }
                }
            }

            &--more {
                @include media-breakpoint-down(md) {
                    position: fixed;
                    bottom: 0;
                    left: 0;
                    z-index: 1;
                    width: 100%;
                    padding: $grid-gutter-width $grid-gutter-width / 2;
                    background-color: $white;
                    border-top: 1px solid $black;

                    .btn {
                        width: 100%;
                    }
                }
            }
        }

        &__doyoumean {
            margin: $grid-gutter-width / 2 0 -#{$grid-gutter-width / 2};

            @include media-breakpoint-up(lg) {
                margin: $grid-gutter-width 0;
            }

            .items {
                display: inline-block;

                &:not(:last-child) .term::after {
                    content: ", ";
                }

                .term {
                    padding-bottom: 0;
                    font-weight: $font-weight-bold;
                }
            }
        }

        &__main {
            @include media-breakpoint-up(lg) {
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
            }

            &--others {
                max-height: 200px;
                margin-top: $grid-gutter-width;
                overflow-y: auto;

                @include media-breakpoint-up(lg) {
                    width: 400px;
                    max-height: 400px;
                    margin-top: 0;
                    margin-right: $grid-gutter-width;
                    padding-right: $grid-gutter-width;
                    border-right: 1px solid $black;
                }

                > ul {
                    margin: $grid-gutter-width 0 0;

                    &:first-of-type {
                        margin-top: 0;
                    }

                    &:empty + ul {
                        margin-top: 0;
                    }
                }
            }

            &--products {
                @include media-breakpoint-up(lg) {
                    width: 100%;
                }
            }
        }

        &__einstein {
            margin-top: $grid-gutter-width;

            @include media-breakpoint-up(lg) {
                display: flex;
            }

            ul {
                @include media-breakpoint-up(lg) {
                    min-width: 320px;
                    margin-right: $grid-gutter-width;
                }
            }

            a {
                text-decoration: none;
            }
        }
    }
}


// -------------------------------------
// SEARCH BANNER
// -------------------------------------

.search-results {
    .grid-header {
        margin: 0;
        text-align: center;

        .floating-breadcrumbs {
            margin: auto 0;
            padding: 5px 0 0;

            .breadcrumb {
                &__list {
                    margin: 0;

                    @include media-breakpoint-up(lg) {
                        position: relative;
                        padding: 0;
                    }
                }
            }
        }

        h1 {
            margin: auto 0;
            text-transform: uppercase;
        }

        h2 {
            margin: $grid-gutter-width 0;
        }

        a {
            font-weight: $font-weight-bold;
        }

        &__top {
            padding-bottom: 5px;

            @include media-breakpoint-up(lg) {
                padding: 0.75em;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
            }
        }

        &.no-results {
            h1,
            h2 {
                text-align: center;
            }
        }
    }

    .search-no-results-banner {
        margin: $grid-gutter-width 0;
        width: 100%;
        height: 200px;

        @include media-breakpoint-up(lg) {
            height: 350px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .show-more-counter {
        &__products {
            font-size: $font-size-sm;
            text-transform: uppercase;
        }
    }

    &__count {
        transform: translateY(-($input-btn-padding-y * 0.5));
        margin-bottom: calc(#{$input-btn-padding-y} * 2);
        text-align: center;
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
            transform: translateY(-($input-btn-padding-y * 1.5));
        }
    }
}
